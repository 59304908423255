import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    //定义首页路由
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home'), // 路由懒加载模式
        meta:{title:'冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}
    },
    //定义通知列表页
    {
        path: '/noticelist',
        name: 'noticelist',
        component: () => import('@/views/NoticeList'), // 路由懒加载模式
        meta:{title:'重要通知 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    //定义新闻列表页
    {
        path: '/newslist',
        name: 'newslist',
        component: () => import('@/views/NewsList'), // 路由懒加载模式
        meta:{title:'文旅资讯 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    //5A列表页
    {
        path: '/5A',
        name: '5A',
        component: () => import('@/views/5A'), // 路由懒加载模式
        meta:{title:'5A创建 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
     // 活动列表
     {
        path: '/activitylist',
        name: 'activitylist',
        component: () => import('@/views/ActivityList'), // 路由懒加载模式
        meta:{title:'精彩活动 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    // 通用列表页3列
    {
        path: '/infolistthree',
        name: 'infolistthree',
        component: () => import('@/views/InfoListThree'), // 路由懒加载模式
        meta:{title:'冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    //定义视频列表页
    {
        path: '/video',
        name: 'video',
        component: () => import('@/views/Video'), // 路由懒加载模式
        meta:{title:'视频掠影 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    //定义视频详情页
    {
        path: '/InfoContent/video/:infoclass?/:id?/',
        name: 'infoContentVideo',
        component: () => import('@/views/InfoContent/video'), // 路由懒加载模式
        meta:{title:'视频掠影 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    //定义图片列表页
    {
        path: '/picture',
        name: 'picture',
        component: () => import('@/views/Picture'), // 路由懒加载模式
        meta:{title:'图游冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    //旅游区景点列表页
    {
        path: '/scenicspot',
        name: 'scenicspot',
        component: () => import('@/views/ScenicSpot'), // 路由懒加载模式
        meta:{title:'旅游区景点 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    //周边景点列表页
    {
        path: '/SurroundingAttractions',
        name: 'SurroundingAttractions',
        component: () => import('@/views/SurroundingAttractions'), // 路由懒加载模式
        meta:{title:'周边景点 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    // 定义信息详情路由
    {
        path: '/infocontent/:infoclass?/:id?/',
        name: 'infocontent',
        component: () => import('@/views/InfoContent'), // 路由懒加载模式
        meta:{title:'冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    // 定义独立页面详情路由
    {
        path: '/SpecialPage/:infoclass?/:id?/',
        name: 'specialPage',
        component: () => import('@/views/SpecialPage'), // 路由懒加载模式
        meta:{title:'冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    // 投诉建议
    {
        path: '/Suggestion/',
        name: 'Suggestion',
        component: () => import('@/views/Suggestion'), // 路由懒加载模式
        meta:{title:'投诉建议 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    // 游玩攻略
    {
        path: '/TourismStrategy/',
        name: 'TourismStrategy',
        component: () => import('@/views/TourismStrategy'), // 路由懒加载模式
        meta:{title:'游玩攻略 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    // 常见问题
    {
        path: '/Problem/',
        name: 'Problem',
        component: () => import('@/views/Problem'), // 路由懒加载模式
        meta:{title:'常见问题 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    // 车辆预订
    {
        path: '/Carbooking/',
        name: 'Carbooking',
        component: () => import('@/views/Carbooking'), // 路由懒加载模式
        meta:{title:'车辆预订 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    // 导游预订
    {
        path: '/GuideBooking/',
        name: 'GuideBooking',
        component: () => import('@/views/GuideBooking'), // 路由懒加载模式
        meta:{title:'导游预订 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    // 酒店预订
    {
        path: '/hotel/',
        name: 'Hotel',
        component: () => import('@/views/Hotel'), // 路由懒加载模式
        meta:{title:'导游预订 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    // 酒店预订详情页
    {
        path: '/hotel/:id?/',
        name: 'HotelDetail',
        component: () => import('@/views/Hotel/detail.vue'), // 路由懒加载模式
        meta:{title:'冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    // VR云上游
    {
        path: '/vr/',
        name: 'VR',
        component: () => import('@/views/VR'), // 路由懒加载模式
        meta:{title:'VR云上游 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    // 搜索
    {
        path: '/Search/',
        name: 'Search',
        component: () => import('@/views/Search'), // 路由懒加载模式
        meta:{title:'搜索 冶力关旅游区 | 国家AAAAA级旅游景区——山水冶力关·生态大观园'}

    },
    // 重定向：访问/，立马重定向到首页
    {
        path: '/',
        redirect: '/home'
    },
]


// 创建路由对象
const router = createRouter({
    //hash模式的路由
    history: createWebHashHistory(),
    routes,
    // 每次切换路由的时候滚动到页面顶部
    scrollBehavior() {
        return { left: 0, top: 0 }
    }
})

export default router
